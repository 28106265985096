.nav {
    background: #fffaf2;
}

.logo {
    width: 100px;
}

.link {
    color: #343f52;
    font-size: 1.1rem;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
}


@media screen and (max-width: 768px) {
    .link {
        border-bottom: 1px solid rgba(var(--bs-navy-rgb), .3);
    }

    .link:hover {
        border-bottom: 1px solid rgba(var(--bs-navy-rgb), 1);
    }
}
    
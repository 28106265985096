@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@100;300;400;600;700&display=swap');

:root {
    --bs-gray-100: #f8f9fa;
    --bs-gray-200: #e9ecef;
    --bs-gray-300: #dee2e6;
    --bs-gray-400: #ced4da;
    --bs-gray-500: #adb5bd;
    --bs-gray-600: #6c757d;
    --bs-gray-700: #495057;
    --bs-gray-800: #343a40;
    --bs-gray-900: #212529;
    --bs-blue: #3f78e0;
    --bs-sky: #5eb9f0;
    --bs-purple: #747ed1;
    --bs-grape: #605dba;
    --bs-violet: #a07cc5;
    --bs-pink: #d16b86;
    --bs-fuchsia: #e668b3;
    --bs-red: #e2626b;
    --bs-orange: #f78b77;
    --bs-yellow: #fab758;
    --bs-green: #45c4a0;
    --bs-leaf: #7cb798;
    --bs-aqua: #54a8c7;
    --bs-navy: #343f52;
    --bs-ash: #9499a3;
    --bs-white: #fff;
    --bs-light: #fefefe;
    --bs-gray: #f6f7f9;
    --bs-dark: #262b32;
    --bs-primary: #3f78e0;
    --bs-secondary: #aab0bc;
    --bs-success: #45c4a0;
    --bs-info: #54a8c7;
    --bs-warning: #fab758;
    --bs-danger: #e2626b;
    --bs-blue-rgb: 63,120,224;
    --bs-sky-rgb: 94,185,240;
    --bs-purple-rgb: 116,126,209;
    --bs-grape-rgb: 96,93,186;
    --bs-violet-rgb: 160,124,197;
    --bs-pink-rgb: 209,107,134;
    --bs-fuchsia-rgb: 230,104,179;
    --bs-red-rgb: 226,98,107;
    --bs-orange-rgb: 247,139,119;
    --bs-yellow-rgb: 250,183,88;
    --bs-green-rgb: 69,196,160;
    --bs-leaf-rgb: 124,183,152;
    --bs-aqua-rgb: 84,168,199;
    --bs-navy-rgb: 52,63,82;
    --bs-ash-rgb: 148,153,163;
    --bs-white-rgb: 255,255,255;
    --bs-light-rgb: 254,254,254;
    --bs-gray-rgb: 246,247,249;
    --bs-dark-rgb: 38,43,50;
    --bs-primary-rgb: 63,120,224;
    --bs-secondary-rgb: 170,176,188;
    --bs-success-rgb: 69,196,160;
    --bs-info-rgb: 84,168,199;
    --bs-warning-rgb: 250,183,88;
    --bs-danger-rgb: 226,98,107;
    --bs-white-rgb: 255,255,255;
    --bs-black-rgb: 0,0,0;
    --bs-body-color-rgb: 96,105,123;
    --bs-body-bg-rgb: 254,254,254;
    --bs-font-theme: 'Urbanist', sans-serif;
    --bs-font-sans-serif: "Manrope",sans-serif;
    --bs-font-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    --bs-root-font-size: 20px;
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: 0.8rem;
    --bs-body-font-weight: 500;
    --bs-body-line-height: 1.7;
    --bs-body-color: #60697b;
    --bs-body-bg: #fefefe;  
    --bs-body-text-inverse-color: #cacaca;
}

body {
    font-family: var(--bs-font-theme)!important;
    background: var(--bs-white)!important;
}

.mx-n2 {
    margin-right: -.5rem !important;
    margin-left: -.5rem !important;
}

.text-inverse {
    color: var(--bs-body-text-inverse-color);
}

.text-inverse a:not(.btn):not([class*="link-"]) {
    color: var(--bs-body-text-inverse-color);
    text-decoration: none;
}

.text-inverse a:not(.btn):not([class*="link-"]):hover {
    color: var(--bs-white);
}

.text-navy {
    color: var(--bs-navy);
}

.bg-navy {
    background: var(--bs-navy)!important;
}


form.dark-fields .form-control {
    background: rgba(255,255,255,.03);
    border-color: rgba(255,255,255,.1);
    color: #cacaca;
    height: calc(1.5em + .8rem + 2px)!important;
}

form.dark-fields .form-control:focus {
    box-shadow: none;
}

form.dark-fields .input-group-text {
    background: rgba(255,255,255,.03);
    border-color: rgba(255,255,255,.1);
    color: #cacaca;
}
.hero {
    background: linear-gradient(180deg,#fffaf2,rgba(255,255,255,0) 100%);
}

.features {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.features > li {
    border: 1px solid var(--bs-navy);
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
}

.features > li > svg {
    width: 2rem;
    height: 2rem;
}

@media screen and (max-width: 768px) {
    .features {
        justify-content: space-between;
    }

    .hero p {
        text-align: justify;
        padding-right: 0!important;
    }

    .hero a {
        display: block;
    }

    
}
    